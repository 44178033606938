export default {

  outAddress () {
    //测试域名 http://swplus-test.shhd.info/hls/aiworkers/#/
    //正式域名https://aiteam.chat/#/
    return "https://"+window.location.host+"/";
  },

  outAddress2 () {
    //测试域名 http://swplus-test.shhd.info/hls/aiworkers/#/
    //正式域名https://aiteam.chat/#/
    return "https://"+window.location.host+"/workbench/#/";
  },

    //判断网页是iOS还是Android
    appSource() {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
            return "ios";
        } else {
            return "andriod";
        }
    },
    //生成一个GUID
    guid() {
        var guid = "";
        for (var i = 1; i <= 32; i++) {
            var n = Math.floor(Math.random() * 16.0).toString(16);
            guid += n;
            if ((i == 8) || (i == 12) || (i == 16) || (i == 20))
                guid += "-";
        }
        return guid;
    },
    //判断字符是否为空的方法
    isStringEmpty(obj) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
            return true;
        } else {
            return false;
        }
    },
    //判断对象是否为空的方法
    isNotEmptyObject(obj) {
        return !!Object.keys(obj).length
      },

    //替换数组自定的元素
    exchangeArrayIndex(array, index, item) {
        if (!array.length) return
        array.splice(index, 1);
        array.splice(index, 0, item);
        return array
    },
    //删除数组指定index元素
    deletArrayIndex(array, index) {
        if (!array.length) return
        array.splice(index, 1);
        return array
    },
    //将数组B的元素添加到数组A的元素后面
    arrayAddItemWitharryB(arrayA, arrayB) {
        if (!arrayA.length || !arrayB.length) return
        for (var i = 0; i < arrayB.length; i++) {
            arrayA.push(arrayB[i])
        }
        return arrayA
    },
    //判断是否是微信浏览器打开
    isWeChat() {
        if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
            return true
        } else {
            return false
        }
    },
    //判断是否是移动端浏览器
    isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    //判断是否是我们自己的App浏览器
    isOurApp() {
        let ua = navigator.userAgent;
        let array = ua.split('$');
        if (array.length > 1) {
            let deviceStr = array[array.length - 1];
            if (deviceStr == 'iOS_HD') {
                return 'us_iOS'
            } else if (deviceStr == 'Android_HD') {
                return 'us_Android'
            } else {
                return false
            }
        } else {
            return false;
        }
    },
    //时间戳格式化 2020/12/30 上午10：53
    getLocalTime(nS) {
        return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    },
    //获取当前时间格式化2020-12-30 10：53：99
    getNowTimeLabel(flag, needSecond) {
         var now = new Date();
         var year = now.getFullYear(); // 年
         var month = now.getMonth() + 1; // 月
         var day = now.getDate(); // 日
         var hour = now.getHours(); // 时
         var minute = now.getMinutes(); // 分
         var second = now.getSeconds(); // 秒
        // 格式化输出时间
        var timeStr;
        if (needSecond) {
            timeStr = year +
                flag +
                this.addZero(month) +
                flag +
                this.addZero(day) +
                " " +
                this.addZero(hour) +
                ":" +
                this.addZero(minute) +
                ":" +
                this.addZero(second);
        } else {
            timeStr = year +
                flag +
                this.addZero(month) +
                flag +
                this.addZero(day) +
                " " +
                this.addZero(hour) +
                ":" +
                this.addZero(minute)
        }

         return timeStr;
    },
    addZero(num) {
        return num < 10 ? "0" + num : num;
    },
    //返回用户类型的角标
    getMemberTip(index) {
        //    会员类型。 2(年费会员) 1（ 学员） 0（ 会员） - 1(过期用户) - 2(免费用户)
        if (parseInt(index) == 2) {
            return "http://cdn.shhd.info/APP/sys_img/detail/bojinTip.png";
        } else if (parseInt(index) == 0) {
            return "http://cdn.shhd.info/APP/sys_img/detail/zuanshiTip.png";
        } else if (parseInt(index) == 1 || index == -2) {
            return "http://cdn.shhd.info/APP/sys_img/detail/huangjinTip1.png";
        } else {
            return "http://cdn.shhd.info/APP/sys_img/other/202112210001.png";
        }
    },
    loadJs(src) {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.type = "text/javascript";
            script.src = src;
            document.body.appendChild(script);

            script.onload = () => {
                resolve();
            }
            script.onerror = () => {
                reject();
            }
        })
    },

    // base64图片转文件流
    base64toFile(data, fileName) {
        const dataArr = data.split(",");
        const byteString = atob(dataArr[1]);
        const options = {
            type: "image/jpeg",
            endings: "native"
        };
        const u8Arr = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            u8Arr[i] = byteString.charCodeAt(i);
        }
        return new File([u8Arr], fileName + ".jpg", options); //返回文件流
    },

    // 获取微信版本
    getWeChatVersion() {
        let m1 = navigator.userAgent.match(/MicroMessenger.*?(?= )/)
        if (m1 && m1.length > 0) {
            return m1[0]
        }
    },

    // 获取设备名和操作系统版本
    getDeviceName() {
        let webLog = {};
        if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
            // ios
            if (navigator.userAgent.includes('iPad')) {
                webLog.device = 'iPad'
            } else {
                webLog.device = 'iPhone'
            }
            let m1 = navigator.userAgent.match(/iPhone OS .*?(?= )/)
            if (m1 && m1.length > 0) {
                webLog.system = m1[0]
            }
            return webLog
        } else if (navigator.userAgent.includes('Android')) {
            // Android
            let m1 = navigator.userAgent.match(/Android.*; ?(.*(?= Build))/)
            if (m1 && m1.length > 1) {
                webLog.device = m1[1]
            }
            m1 = navigator.userAgent.match(/Android.*?(?=;)/)
            if (m1 && m1.length > 0) {
                webLog.system = m1[0]
            }
            return webLog
        }
    },

    // 获得格式化时间 yyyy-MM-dd
    getFormatTime(res) {
        let year = new Date(res).getFullYear();
        let month = new Date(res).getMonth() + 1;
        if (month < 10) {
            month = '0' + month
        }
        let day = new Date(res).getDate();
        if (day < 10) {
            day = '0' + day
        }
        let hour = new Date(res).getHours();
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = new Date(res).getMinutes();
        if (minute < 10) {
            minute = '0' + minute
        }
        let second = new Date(res).getSeconds();
        if (second < 10) {
            second = '0' + second
        }
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
        // let year = new Date().getFullYear
    },
    //替换指定传入参数的值,url为地址,paramName为参数,replaceWith为新值
    replaceParamVal(url, paramName, replaceVal) {
        var oUrl = url.toString();
        var re = eval('/(' + paramName + '=)([^&]*)/gi');
        var nUrl = oUrl.replace(re, paramName + '=' + replaceVal);
        return nUrl;
    },

    //获取课程名
    receiveCourseName(name) {
        var reg = RegExp(/#/);
        if (name.match(reg)) {
            var arr = name.split("#")
            return arr[0];
        } else {
            return name;
        }
    },

    //网络头像（没有用展位图）
    netHeaderImgUrl(headImgUrl) {
        if (this.isStringEmpty(headImgUrl)) {
            return "http://cdn.shhd.info/APP/sys_img/default.png";
        } else {
            return headImgUrl;
        }
    },
    //字符串中间加*号
    visibilityChange(val, topNum, bottomNum) {
        let _len = val.length
        return val.substring(0, topNum) + '****' + val.substring(_len - bottomNum, _len)
    },
//对象深拷贝
   deepClone(source) {
       if (typeof source !== 'object' || source == null) {
           return source;
       }
       const target = Array.isArray(source) ? [] : {};
       for (const key in source) {
           if (Object.prototype.hasOwnProperty.call(source, key)) {
               if (typeof source[key] === 'object' && source[key] !== null) {
                   target[key] = deepClone(source[key]);
               } else {
                   target[key] = source[key];
               }
           }
       }
       return target;
   },

}