import { request } from '../request'

import loaclstore from 'store-gem.js'
var localstore = new loaclstore()
//获取是否显示图形验证码  
export function isVerifyCode() {
  return request({
      url: 'api-n/notification-anon/isVerifyCode',
      method: 'post',
  })
}
//获取验证码V3
export function codesV3(phoneNum, countryCode,timeStamp,verifyCode) {
  return request({
      url: 'api-n/notification-anon/codesV3',
      method: 'post',
      data: {
        "phone": phoneNum,
        "phoneCode": countryCode,
        "timeStamp": timeStamp,
        "verifyCode": verifyCode
    }
  })
}

//登录接口
export function loginActionNet(phoneDataDic) {
  return request({
      url: 'api-u/users-anon/phoneLogin?code=' + phoneDataDic.code + "&phone=" + phoneDataDic.phone + "&phoneCode=" + phoneDataDic.phoneCode + "&key=" + phoneDataDic.key + '&sourceFrom=' + phoneDataDic.sourceFrom + '&fromUserId=' + phoneDataDic.fromUserId + '&openId=' + phoneDataDic.openId,
      method: 'post',
  })
}
export function checkCode(phone,vcode,key) {
  return request({
      url: 'api-u/users-anon/checkCode?phone='+phone+"&code="+vcode+"&key="+key,
      method: 'post',
  })
}

export function wxLogin(openid) {
  return request({
    url: 'api-u/users-anon/wxLogin?openid=' + openid + "&nickname=''"+"&headImgUrl=''",
    method: 'post',
   
  })
}

export function wxRegister(obj) {
  return request({
    url: 'api-u/users-anon/wxRegister',
    method: 'post',
    data: obj,
  })
}