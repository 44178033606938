<!--  -->
<template>
  <div class="login-contain">

    <div class="login-box-desk">
      <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose" top="60px">
        <div class="login-box-desk2">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png" @click="show = false"
            class="img-close">

          <div class="login-logo">

            <img src="https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/wiseailogo.png">
            <p>慧言互动AI</p>
          </div>


          <p class="p-logo1">登录/注册</p>
          <p class="p-logo2"></p>

          <p class="p-logo3" v-show="wxflag">新用户扫码后，关注公众号自动创建账号</p>

          <div v-show="!wxflag">

            <div class="div-form">
              <p class="div-form-title">手机号</p>

              <div class="login-input-box">

                <el-input type="text" placeholder="请输入手机号" v-model="phoneNum" maxlength="15"
                  oninput="value=value.replace(/[^0-9]/g,'')">
                </el-input>
              </div>

            </div>

            <div class="div-form">
              <p class="div-form-title">图形验证码</p>

              <div class="login-input-box">

                <el-input type="text" placeholder="请输入图形验证码" v-model="imgCode" maxlength="6">
                </el-input>

                <img :src="picCodeImg" class="img-code" @click="changeCode">
              </div>

            </div>


            <div class="div-form">
              <p class="div-form-title">验证码</p>
              <div class="login-input-box">
                <el-input type="text" placeholder="请输入验证码" v-model="vCode" maxlength="6"
                  oninput="value=value.replace(/[^0-9]/g,'')">
                </el-input>

                <p class="p-vcode" @click="getVcode">{{ vcodeCount }}</p>
              </div>

            </div>

            <div class="btn-login">
              <el-button type="primary" :loading="btnloading" :disabled="!(phoneNum && vCode)"
                @click="loginBtn(1)">登录/注册</el-button>


            </div>

          </div>

          <div v-show="wxflag">

            <img :src="wxQrcode" class="wxqrcode" v-loading="wxloading">

            <div class="wxqrcode2">
              <p class="wxqrcode-p1">请使用微信扫码登录</p>
              <div style="display: flex;align-items: center; cursor: pointer;">
                <p class="wxqrcode2-p2" @click="getWxQrcode">刷新</p>
                <img :class="{ 'rotate-div': false }"
                  src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/refreshblue.png" class="wxqrcode2-img">

              </div>
            </div>


          </div>

          <div class="btn-login-xy">
            <p class="xy1">登录/注册即表示同意</p>
            <p class="xy2" @click="lookXieyi(1)">《用户服务协议》</p>
            <p class="xy2" @click="lookXieyi(2)">《隐私协议》</p>
          </div>

          <div class="qiehuan" v-show="!wxflag" @click="qiehuanWx(true)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/wxicon.png">
            <p>{{ wxLogintip }}</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/yjtzs.png" alt="">
          </div>
          <div class="qiehuan" v-show="wxflag" @click="qiehuanWx(false)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/shoujiicon.png">
            <p>使用手机号验证登录</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/yjtzs.png" alt="">
          </div>


        </div>
      </el-dialog>
    </div>


    <div class="login-bindphone">

      <!-- 绑定手机号 -->
      <el-dialog :visible.sync="wxPayDialog" :show-close="false" :before-close="handleClose" top="calc(50vh - 190px)">
        <div class="div-dialog">
          <div class="drawer-head">
            <p>绑定手机号</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png" @click="wxPayDialog = false">
          </div>


          <div class="div-form4">
            <p class="div-form-title">手机号</p>

            <div class="login-input-box">

              <el-input type="text" placeholder="请输入手机号" v-model="phoneNum" maxlength="15"
                oninput="value=value.replace(/[^0-9]/g,'')">
              </el-input>
            </div>

          </div>
          <div class="div-form4">
            <p class="div-form-title">图形验证码</p>

            <div class="login-input-box">

              <el-input type="text" placeholder="请输入图形验证码" v-model="imgCode" maxlength="6">
              </el-input>

              <img :src="picCodeImg" class="img-code" @click="changeCode">
            </div>

          </div>

          <div class="div-form4">
            <p class="div-form-title">验证码</p>
            <div class="login-input-box">
              <el-input type="text" placeholder="请输入验证码" v-model="vCode" maxlength="6"
                oninput="value=value.replace(/[^0-9]/g,'')">
              </el-input>

              <p class="p-vcode" @click="getVcode">{{ vcodeCount }}</p>
            </div>

          </div>


          <div class="btn-login">
            <el-button type="primary" :loading="btnloading" :disabled="!(phoneNum && vCode)"
              @click="loginBtn(2)">确定</el-button>


          </div>





        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import common from "common/js/common.js"
import axios from "axios";
import { codesV3, checkCode, wxLogin, wxRegister } from "api/aiworker/login.js";
const instance = axios.create({
  // baseURL: "http://118.178.187.110:8086/api-aicc/v1/chat",
  baseURL: "https://www.wiseai.chat/",
  timeout: 50000,
})
export default {
  name: 'Login',

  data () {
    return {
      id: null,
      show: false,
      showIndex: 1,
      wxflag: false,
      wxloading: false,
      count: 0,
      codeKey: '',
      phoneNum: "",
      timer: null,
      timeStamp: "",
      imgCode: '',
      picCodeImg: '',
      vCode: "",
      vcodeCount: "获取验证码",
      btnloading: false,
      value2: '+86',
      wxQrcode: null,
      wxticket: null,
      timerLx: null,
      timerDs: null,
      wxcount: 300,
      wxPayDialog: false,
      countryList: [
        { name: "中国 (China)", areaCode: "86" },
        { name: "中国香港 (Hong Kong)", areaCode: "852" },
        { name: "中国澳門 (Macau)", areaCode: "853" },
        { name: "中国台湾 (Taiwan)", areaCode: "886" },
        { name: "日本 (Japan)", areaCode: "81" },
        { name: "韩国 (South Korea)", areaCode: "82" },
        { name: "美国/加拿大 (USA or Canada)", areaCode: "1" },
        { name: "马来西亚 (Malaysia)", areaCode: "60" },
        { name: "新加坡 (Singapore)", areaCode: "65" },
        { name: "澳大利亚 (Australia)", areaCode: "61" },
        { name: "奥地利 (Austria)", areaCode: "43" },
        { name: "法国 (France)", areaCode: "33" },
        { name: "西班牙 (Spain)", areaCode: "34" },
        { name: "英国 (United Kingdom)", areaCode: "44" },
      ],
      device: '',
      openid: '',
      wxLogintip: '使用微信扫码登录',

    }
  },


  methods: {
    lookXieyi (index) {
      if (index == 1) {
        window.open('https://alidocs.dingtalk.com/i/p/AO2RXDNMerdNGZjEkoz3wq3j4W8gkz6L');
      } else {
        window.open('https://alidocs.dingtalk.com/i/p/AO2RXDNMerdNGZjEkoz3wq8OkJwKkz6L');
      }


    },
    changeCode () {

      this.timeStamp = new Date().getTime() + "" + Math.floor(Math.random() * 100000 + 1);
      this.picCodeImg = 'https://server.wiseai.chat/api-s/official-anon/createVerifyCode?key=' + this.timeStamp;
    },
    handleClose (done) {

    },
    getVcode () {
      if (this.vcodeCount === '获取验证码' || this.vcodeCount === '重新获取') {
        if (common.isStringEmpty(this.phoneNum)) {
          this.$message('请输入手机号');
          return;
        }
        if (common.isStringEmpty(this.imgCode)) {
          this.$message('请输入图形验证码');
          return;
        }
        const TIME_COUNT = 60;
        if (!this.timer) {
          console.log("倒计时");
          this.count = TIME_COUNT;
          this.vcodeCount = this.count + "秒后重试";
          codesV3(this.phoneNum, this.value2.substr(1), this.timeStamp, this.imgCode)
            .then((result) => {
              console.log(result);
              if (!common.isStringEmpty(result.key)) {
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    this.vcodeCount = this.count + "秒后重试";
                  } else {

                    clearInterval(this.timer);
                    this.timer = null;
                    this.vcodeCount = "获取验证码";
                  }
                }, 1000);
                this.codeKey = result.key;
              } else {
                this.$message.error(result.message);
              }
            })
            .catch((err) => {
              clearInterval(this.timer);
              this.timer = null;
              this.vcodeCount = "获取验证码";

              this.$message.error('获取验证码失败');

            });
        }


      }
    },



    loginBtn (obj2) {
      if (common.isStringEmpty(this.phoneNum)) {
        this.$message('请输入手机号');
        return;
      }
      if (common.isStringEmpty(this.vCode)) {
        this.$message('请输入短信验证码');
        return;
      }
      this.btnloading = true;
      checkCode(this.phoneNum, this.vCode, this.codeKey).then((res) => {
        this.btnloading = false;

        console.log(obj2);
        if (res.code == 200) {

          if (obj2 == 2) {

            console.log("1111111111111");

            this.wxuserRegister();
          } else {


            this.$localstore.set(
              "hdUserId",
              res.data.loginUser.id
            );

            this.$localstore.set(
              "nickname",
              res.data.loginUser.nickname
            );
            this.$store.commit('user/changeuserName', res.data.loginUser.nickname);

            this.$localstore.set(
              "headImgUrl",
              res.data.loginUser.headImgUrl
            );


            this.$store.commit('user/changeheadImgurl', res.data.loginUser.headImgUrl);
            this.$localstore.set(
              "phone",
              res.data.loginUser.phone
            );
            this.$store.commit('user/changeToken', res.data.access_token);
            this.$localstore.set(
              "access_token",
              res.data.access_token
            );
            if (this.showIndex == 1) {
              //常见员工登录
              this.show = false;
              location.href = this.$currentServe + "backend/#/emp/myyg";

            } else if (this.showIndex == 3) {
              //右上角登录

              this.show = false;
              location.href = this.$currentServe + "backend/#/";

            } else if (this.showIndex == 2) {

              //当前页面
              this.show = false;
              this.$emit("refreshModelAction", -1, null);

            } else if (this.showIndex == 4) {
              this.show = false;
              this.$emit("refreshModelAction", 1, this.id);
            } else if (this.showIndex == 5) {
              this.show = false;
              this.$emit("refreshModelAction", 2, this.id);
            }




          }

          /*
          userInfo().then((res2) => {
            if (res2.code == 200) {

              this.$localstore.set(
                "nickname",
                res2.data.nickname
              );
              this.$localstore.set(
                "headImgUrl",
                res2.data.headImgUrl
              );
              this.$localstore.set(
                "orgId",
                res2.data.orgId
              );

              if (res2.data.operatorType != null) {
                this.$localstore.set("isOrgType", res2.data.operatorType + "");
              } else { 
                this.$localstore.set("isOrgType", "0");
              }

              this.$localstore.set("parentOrgId", res2.data.parentOrgId);
              this.$localstore.set("isOrg", res2.data.isParentOrgId + "");



              this.$router.push({ path: '/' })

            } else {

              if (!common.isStringEmpty(res2.message)) {
                this.$message(res2.message);
              }

            }
          }).catch((err) => {

            if (!common.isStringEmpty(JSON.stringify(err))) {
              this.$message(JSON.stringify(err.message));
            }
          });
 */




        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },

    showLoginDialog (index, id) {
      this.show = true;
      this.showIndex = index;
      this.id = id;
    },

    /* 
      * 关闭弹窗
      */
    codeDialogClose () {
      console.log('关闭弹窗')
      this.controller.abort();

    },

    qiehuanWx (obj) {
      if (this.wxLogintip == '微信一键登录') {

        this.wxuserLogin(this.openid);

      } else {
        this.wxflag = obj;
        if (obj) {
          this.wxcount = 300;
          if (this.timerDs != null) {
            clearInterval(this.timerDs);
          }
          this.timerDs = setInterval(() => {

            if (this.wxcount > 0) {
              this.wxcount--;

            } else {
              if (this.timerLx != null) {
                clearInterval(this.timerLx);
                this.timerLx = null;
              }
              clearInterval(this.timerDs);
              this.timerDs = null;


            }

          }, 1000);

          this.getWxQrcode();
        } else {

          if (this.timerLx != null) {
            clearInterval(this.timerLx);
            this.timerLx = null;
          }

          if (this.timerDs != null) {
            clearInterval(this.timerDs);
            this.timerDs = null;
          }
        }
      }



    },

    getWxQrcode () {
      instance({
        url: 'wxManager-getQR?token=123456',
        method: 'get',

      }).then((res2) => {
        console.log(res2);
        if (res2.data.code == 200) {

          this.wxQrcode = res2.data.url;



          if (this.timerLx != null) {
            clearInterval(this.timerLx);
            this.timerLx = null;
          }

          this.timerLx = setInterval(() => {



            this.getWxdata(res2.data.ticket);
          }, 4000);


        } else {

          if (!common.isStringEmpty(res2.data.message)) {
            this.$message.error(res2.data.message);
          }


        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });

    },

    getWxdata (obj) {

      this.wxloading = true;
      instance({
        url: 'wxManager-getOpenId?ticket=' + obj,
        method: 'get',

      }).then((res2) => {
        console.log(res2);
        if (res2.status == 200) {
          if (res2.data != 0 && !common.isStringEmpty(res2.data)) {

            if (this.timerLx != null) {
              clearInterval(this.timerLx);
              this.timerLx = null;
            }
            if (this.timerDs != null) {
              clearInterval(this.timerDs);
              this.timerDs = null;
            }
            this.openid = res2.data;

            this.wxuserLogin(res2.data);

          }


        } else {

          if (!common.isStringEmpty(res2.message)) {
            this.$message.error(res2.message);
          }


        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });


    },

    wxuserLogin (openid) {

      wxLogin(openid).then((res) => {
        if (this.timerLx != null) {
          clearInterval(this.timerLx);
          this.timerLx = null;
        }
        if (this.timerDs != null) {
          clearInterval(this.timerDs);
          this.timerDs = null;
        }
        this.wxloading = false;
        if (res.code == 200) {


          this.$localstore.set(
            "hdUserId",
            res.data.loginUser.id
          );

          this.$localstore.set(
            "nickname",
            res.data.loginUser.nickname
          );
          this.$store.commit('user/changeuserName', res.data.loginUser.nickname);

          this.$localstore.set(
            "headImgUrl",
            res.data.loginUser.headImgUrl
          );


          this.$store.commit('user/changeheadImgurl', res.data.loginUser.headImgUrl);
          this.$localstore.set(
            "phone",
            res.data.loginUser.phone
          );
          this.$store.commit('user/changeToken', res.data.access_token);
          this.$localstore.set(
            "access_token",
            res.data.access_token
          );


          if (this.showIndex == 1) {
            //常见员工登录
            this.show = false;
            location.href = this.$currentServe + "backend/#/emp/myyg";

          } else if (this.showIndex == 3) {
            //右上角登录

            this.show = false;
            location.href = this.$currentServe + "backend/#/";

          } else if (this.showIndex == 2) {

            //当前页面
            this.show = false;
            this.$emit("refreshModelAction", -1, null);

          } else if (this.showIndex == 4) {
            this.show = false;
            this.$emit("refreshModelAction", 1, this.id);
          } else if (this.showIndex == 5) {
            this.show = false;
            this.$emit("refreshModelAction", 2, this.id);
          }



          //   this.$router.push({ path: '/' })


        } else if (res.code == 400) {

          if (this.timerLx != null) {
            clearInterval(this.timerLx);
            this.timerLx = null;
          }
          if (this.timerDs != null) {
            clearInterval(this.timerDs);
            this.timerDs = null;
          }
          this.$message('请先绑定手机号');
          this.wxPayDialog = true;


        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        this.wxloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });

    },

    wxuserRegister () {

      let opo = {
        phone: this.phoneNum,
        code: '86',
        openid: this.openid,
        headImgUrl: '',
        nickname: ''

      };
      this.btnloading = true;
      wxRegister(opo).then((res) => {

        this.btnloading = false;
        if (res.code == 200) {

          this.$localstore.set(
            "hdUserId",
            res.data.loginUser.id
          );

          this.$localstore.set(
            "nickname",
            res.data.loginUser.nickname
          );
          this.$store.commit('user/changeuserName', res.data.loginUser.nickname);

          this.$localstore.set(
            "headImgUrl",
            res.data.loginUser.headImgUrl
          );


          this.$store.commit('user/changeheadImgurl', res.data.loginUser.headImgUrl);
          this.$localstore.set(
            "phone",
            res.data.loginUser.phone
          );
          this.$store.commit('user/changeToken', res.data.access_token);
          this.$localstore.set(
            "access_token",
            res.data.access_token
          );

          if (this.showIndex == 1) {
            //常见员工登录
            this.show = false;
            location.href = this.$currentServe + "backend/#/emp/myyg";

          } else if (this.showIndex == 3) {
            //右上角登录

            this.show = false;
            location.href = this.$currentServe + "backend/#/";

          } else if (this.showIndex == 2) {

            //当前页面
            this.show = false;
            this.$emit("refreshModelAction", -1, null);

          } else if (this.showIndex == 4) {
            this.show = false;
            this.$emit("refreshModelAction", 1, this.id);
          } else if (this.showIndex == 5) {
            this.show = false;
            this.$emit("refreshModelAction", 2, this.id);
          }



        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },



    $_resizeHandler () {

      if (document.body.getBoundingClientRect().width - 1 < 992) {
        this.device = 'mobile'
      } else {
        this.device = 'desktop'
      }
    },


    selectValue (s) {

      //      this.value2 = s;


      console.log(s);

    }
  },

  beforeMount () {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.$_resizeHandler)
  },


  mounted () {
    if (!common.isStringEmpty(this.$route.query.openid)) {
      this.openid = this.$route.query.openid;
      this.wxLogintip = '微信一键登录'
    }

    this.timeStamp = new Date().getTime() + "" + Math.floor(Math.random() * 100000 + 1);
    this.picCodeImg = 'https://server.wiseai.chat/api-s/official-anon/createVerifyCode?key=' + this.timeStamp;

    if (document.body.getBoundingClientRect().width - 1 < 992) {
      this.device = 'mobile'
    } else {
      this.device = 'desktop'
    }

    console.log(this.device);
  },
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.login-box-desk /deep/.el-dialog {


  width: 560px;
  height: 680px;
  background: linear-gradient(180deg, #EDF4FF 0%, #F6F9FF 100%);
  border-radius: 14px 14px 14px 14px;
  border: 1px solid #FFFFFF;
}

.login-box-desk2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
  height: 680px;

}

.login-logo {
  display: flex;
  align-items: center;

  margin-top: 40px;

}

.login-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
}

.login-logo p {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  margin-left: 8px;
}

.lzlogo {
  width: 161px;
  margin-top: 40px;
}

.p-logo1 {
  font-size: 22px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #192A48;
  line-height: 22px;
  margin-top: 22px;
}

.p-logo2 {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #192A48;
  line-height: 16px;
  margin-top: 11px;
  margin-bottom: 20px;

}

.p-logo3 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #192A48;
  line-height: 14px;
  margin-top: -11px;
  margin-bottom: 16px;

}

.marginTop {
  margin-top: 30px;
}

.div-form {
  margin: 18px 0px 0px 0px;
  width: 340px;

}

.div-form-title {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 14px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;

}



.login-left-img {
  width: 308px;
  height: 528px;
}

.img-close {
  position: absolute;
  width: 36px;
  height: 36px;
  right: 0;
  margin-top: 12px;
  margin-right: 15px;
  cursor: pointer;
}

.login-right {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  width: 520px;
  height: 528px;
}

.login-title-lab {
  font-size: 30px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 30px;
  margin-top: 52px;
  margin-bottom: 12px;
}

.login-input-lab {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  margin-top: 18px;
}

.login-input-box {
  display: flex;
  position: relative;
  width: 100%;
  height: 46px;

}

.btn-login {
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-top: 28px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.btn-login-xy {

  display: flex;
  align-items: center;
  margin-top: 18px;
  align-self: center;
}

.xy1 {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, .4);
  line-height: 12px;
}

.xy2 {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  line-height: 12px;
  margin-left: 2px;
  cursor: pointer;
}

.qiehuan {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 50px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #E0E9EF;
  bottom: 50px;
  cursor: pointer;
}

.qiehuan p {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(25, 42, 72, .7);
  line-height: 14px;
  margin-left: 2px;
  margin-right: 2px;

}

.wxqrcode {
  width: 180px;
  height: 180px;
  box-shadow: 0px 6px 12px 0px #E6EDF9;
  border-radius: 8px 8px 8px 8px;
  margin-top: 50px;
}

.wxqrcode2 {
  display: flex;
  margin-top: 25px;
  align-self: center;
  align-items: center;
}

.wxqrcode-p1 {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: rgba(25, 42, 72, .6);
  line-height: 16px;
  margin-right: 10px;
}

.wxqrcode2-p2 {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  line-height: 16px;
}

.wxqrcode2-img {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}

.rotate-div {

  animation: rotate 1s infinite linear;
  /* 实现旋转动画效果 */
}

.qiehuan :nth-child(1) {
  width: 32px;
  height: 32px;
}



.qiehuan :nth-child(3) {
  width: 11px;
  height: 11px;
}

/deep/ .el-button {
  width: 100%;
  height: 50px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 600;

}

/deep/.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {

  background-color: #a0cfff;
  border-color: #a0cfff;
}

.tip {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, .4);
  line-height: 12px;
  margin-top: 16px;
  align-self: center;
}

.login-input-box /deep/.el-dropdown-menu__wrapper {
  max-height: 200px;
  overflow: hidden;
}

.area-sele {
  width: 68px;
  height: 100%;
}

.area-sele /deep/ .el-input__inner {

  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color: #000000;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;

  border: 1px solid #EAF1F5;
  padding-left: 8px;
  padding-right: 0px;

}

.area-sele /deep/ input::-webkit-input-placeholder {

  color: rgba(0, 0, 0, .4);

}

/deep/.el-icon-arrow-up:before {}

.area-sele /deep/ .el-select .el-input .el-input__suffix {
  right: 1px;
  color: #000 !important;
}

/deep/ .el-select__caret {
  color: #000000;
}

.area-sele /deep/ .el-input__inner:focus {
  line-height: 50px;
}

.area-sele /deep/ .el-input__inner:focus {

  outline: 0;
}

.area-sele /deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

.line-shu {
  width: 1px;
  height: 15px;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.2);


}


/**改变input里的字体颜色*/
/deep/ input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

/deep/ .el-input__inner {

  height: 46px;
  line-height: 100%;
  font-size: 16px;
  padding-right: 87px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #E1EBF6;
  color: #000000;

}

/deep/ .el-input__inner:focus {
  border: 1px solid #226CFF;

}

.img-code {

  height: 44px;
  border-radius: 0 8px 8px 0;
  position: absolute;
  right: 1px;
  top: 1px;
}

.p-vcode {
  width: 87px;
  height: 46px;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  line-height: 46px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
}



/* 移动端 */
.login-box-mobile {

  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #E9F6FF 0%, #FFFFFF 100%);
  border-radius: 14px 14px 0px 0px;
  width: 100%;
  height: 500px;

  align-self: flex-end;
}

.mobile-title-lab {

  font-size: 21px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 21px;
  margin-top: 30px;

}

.marginleft {
  margin-left: 10px;
  align-self: flex-start;
}

.mobi-input-box {
  display: flex;

  width: 380px;
  height: 51px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  margin-top: 8px;
  border: 1px solid #E1EBF6;
}

.login-bindphone /deep/.el-dialog {

  width: 360px;

  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;

}

.div-dialog {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.drawer-head {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.drawer-head p {
  flex: 1;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
  margin-left: 20px;
}

.drawer-head img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}


/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  padding: 0;
}

.div-form4 {
  margin: 18px 10px 0px 10px;
  width: 340px;



}
</style>