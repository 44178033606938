import Vue from 'vue'
import App from './App.vue'
import VueLazyLoad from 'vue-lazyload'
import router from './router'
import store from './store'

import FastClick from 'fastclick'
import 'assets/style/index.styl'
import VueResource from 'vue-resource'
import animated from 'animate.css'
import Base64 from 'common/js/base64.js'
import loaclstore from 'store-gem.js'
import Loading from 'vue-loading-spin'
import 'vue-loading-spin/dist/loading.css'
import VueClipboard from 'vue-clipboard2'
import VueQr from 'vue-qr'
import VueJsBridge from 'vue-webview-js-bridge'
import common from "common/js/common";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp']
//解决移动端300ms延迟
FastClick.attach(document.body)

FastClick.prototype.focus = (ele) => {
  'use strict';
  ele.focus();
};
Vue.use(ElementUI);
Vue.use(VueLazyLoad, {
  preLoad: 1,
  loading: require('assets/logo.png')
})
Vue.use(require('vue-titles'))
Vue.use(animated).use(VueClipboard)
Vue.use(VueJsBridge, {
  debug: false,
  nativeHandlerName: 'iOSRegisterHandler',
  mock: false,
  mockHandler (payload, next) {
    // mock by payload
    // call next(data) to mock data
  }
  // ...
})
//https://www.wiseai.chat/
//https://aitest.wiseai.chat/

Vue.prototype.$currentServe = process.env.VUE_APP_MODE === 'production' ? 'https://www.wiseai.chat/' : 'https://aitest.wiseai.chat/';
Vue.prototype.$currentChat = process.env.VUE_APP_MODE === 'production' ? 'https://www.wiseai.chat/chat/#aWQ9MTA1MiZvcmdJZD02Ng' : 'https://aitest.wiseai.chat/chat/#aWQ9MTA1MSZvcmdJZD0xMjU'
Vue.prototype.$localstore = new loaclstore()
Vue.prototype.$common = common
Vue.prototype.$Base64 = Base64;
// NProgress.inc(0.1)
// NProgress.configure({
//   easing: 'ease',
//   speed: 500,
//   showSpinner: true
// })
router.beforeEach((to, from, next) => {
  // NProgress.start()
  if (to.path === '/model') {
    sessionStorage.setItem('main_index', 2)
  }
  next()
})

// router.afterEach((to, from) => {
//   NProgress.done()

// })


router.afterEach((to, from) => {
  // NProgress.done()
  // PV埋点
  /*
  const { aplus_queue } = window;
  const userId = Vue.prototype.$localstore.get("hdUserId") || '';
  // console.log(userId);
  aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }, { page_path: to.path, userId: userId, }]
  });
  aplus_queue.push({
    action: 'aplus.record',
    arguments: ['enterPage_custom', 'CLK', { page_path: to.path, userId: userId, }]
  });
  */
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

