import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.replace
    //修改原型对象中的push方法
VueRouter.prototype.replace = function replace(location) {
        return originalPush.call(this, location).catch(err => err)
    }
const worker = () => import('../views/aiworker/Worker.vue')
const createworker = () => import('../views/aiworker/Createworker.vue')
const advanced = () => import('../views/aiworker/Advanced.vue')


const createprompt = () => import('../views/aiworker/Createprompt.vue')

const editprompt = () => import('../views/aiworker/cells/Editprompt.vue')

const fileupload = () => import('../views/aiworker/cells/Fileupload.vue')
const usermanager = () => import('../views/aiworker/Usermanager.vue')

const aisection = () => import('../views/aiworker/Aisection.vue')
// 2.创建router
const routes = [
 

  {
    path: '/',
    component: () => import('../views/main.vue'),
    redirect: '',
    children: [
      {
        path: '',
        component: () => import('../views/home/Home.vue'),
      },
      {
        path: 'model',
       
        component: () => import('../views/modellist/Modellist.vue'),
      },
    ]
  },
  
  {
    path: '/modelmarket',
    component: () => import('../views/modellist/Modellist2.vue'),
  },
  {
    path: '/wkr',
    component: worker,
  
    children: [
      {
        path: 'create',
        component: createworker
      },
      {
        path: 'advanced',
        name:'advanced',
        component: advanced
      },
    ]
  },
  {
    path: '/pmt',
    
    component: createprompt,
    redirect: '/pmt/fileup',
    children: [
      {
        path: 'fileup',
        component: fileupload
      },
      {
        path: 'ept',
        name:'ept',
        component: editprompt
      },
    ]

  },
  {
    path: '/um',
    component: usermanager
  }, 
  {
    path: '/qa',
    component:aisection
  }
  
]

export default new VueRouter({

  base: "./",
  mode: 'history',
  routes
});