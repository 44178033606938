import loaclstore from 'store-gem.js'
var localstore = new loaclstore()

const state = {
 
  access_token:localstore.get('access_token'),
  headImgUrl: localstore.get('headImgUrl'),
  userName: localstore.get('nickname'),
  


}

const mutations = {

  changeToken (state,res) { 
    state.access_token = res;
  },
  changeheadImgurl (state,res) { 
    state.headImgUrl = res;
  },

  changeuserName (state,res) { 
    state.userName = res;
  },
 
 
}

const actions = {}



export default {
  namespaced: true,
  state,
  mutations,
  actions,

}
