<template>
  <!--  id="app" -->
  <div>

    <router-view />


  </div>
</template>

<script>
import common from "./common/js/common";
import Loginbox from "./components/loginbox/loginbox.vue";
export default {
  name: "app",
  components: {
    Loginbox,

  },
  data () {
    return {

      selectIndex: 0,
      mengban: true,
    };
  },
  computed: {

    access_token () {
      return this.$store.state.user.access_token
    },
    userName () {
      return this.$store.state.user.userName
    },
    headImgUrl () {
      return this.$store.state.user.headImgUrl
    },


  },

  methods: {
    selectItem (index) {
      if (this.selectIndex == index) return;
      this.selectIndex = index;
      if (index == 0) {
        if (this.$route.path != '/') {
          this.$router.replace({ path: '/' })
        }
        sessionStorage.setItem('main_index', 0)
        this.jumbTop();

      } else if (index == 1) {
        if (this.$route.path != '/') {
          this.$router.replace({ path: '/' })
        }
        this.$refs.child.jumbQuestion()
        sessionStorage.setItem('main_index', 1)
      } else if (index == 2) {
        this.$router.replace({ path: '/model' })
        sessionStorage.setItem('main_index', 2)
      }
    },
    openWork () {
      location.href = this.$currentServe + "backend/#/";
    },
    openlogin2 (index) {

      this.$refs.childLogin.showLoginDialog(index)
    },
    refreshModel () {
      console.log("33333333333");
      this.$refs.child.refreshModel2();
    },
    jumbTop () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openLogin () {
      this.$refs.childLogin.showLoginDialog(1)
    },
    logout () {



      this.$localstore.remove('access_token')
      this.$store.commit('user/changeToken', null);
      this.$localstore.remove('hdUserId')
      this.$localstore.remove('nickname')
      this.$store.commit('user/changeuserName', '');
      this.$localstore.remove('headImgUrl')
      this.$store.commit('user/changeheadImgurl', '');

    }

  },

  created () {
    if (window.screen.availWidth <= 1000) {
      window.location.replace(this.$currentServe + "mobile/#/home");
      return;
    } else {
      this.mengban = false;
    }

    if (sessionStorage.getItem('main_index') == null) {

      this.selectIndex = 0;
    } else {
      this.selectIndex = sessionStorage.getItem('main_index')
    }
  },
  mounted () {

  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
@import 'assets/css/base.css';
@import 'assets/style/variable.styl';

.el-popover.my-popover{

  padding:0;
  background:rgba(0,0,0,0);
  border:0;
  
  }
  // .main{
    //   position: relative;
    // }
  .main-mengban{
    position:fixed;
    top:0;
    width:100%;
    height:100%;
    background:#ffffff;
    z-index:222;
  }

  // .home-header {
    //   z-index: 99;
    //   width: 100%;
    //   height: 56px;
    //   background: #ffffff;
    //   box-shadow: 0px 1px 1px 0px rgba(9, 56, 128, 0.06);
    //   opacity: 1;
    
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // } 
  
  .logo-img {
    width: 102px;
    height: 38px;
    margin-left: 34px;
    cursor: pointer;
    // position: absolute;
    // left: 0;
  }
  .home-center{
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav{
   
   
 
   
    line-height: 56px;
      
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 400;
        color: #999999;
        padding-left:50px;
        padding-right:50px;
       
  }

  
  .active{
    
    color: #226cff;
    font-weight: 600;
  }
  .home-right{
    //  position: absolute;
    // right: 0;
     margin-right: 25px;
   
   }
  .home-right-person{
    display: flex;
    align-items: center;
  }
  .home-ps-p{
    font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  margin-right: 6px;
  }
  .home-ps-img{
    width: 36px;
        height: 36px;
        border-radius: 18px;
        opacity: 1;
        border: 1px solid rgba(0, 0, 0, 0.03);
        cursor: pointer;
     
  }
  
  .login-btn {
    width: 108px;
        height: 36px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 36px;
        margin-right: 10px;
        /* background-color: red; */
        cursor: pointer;
        text-align: center;
        border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #E6EBF3;
      }
      .free-use-btn {
        width: 108px;
        height: 36px;
        background: linear-gradient(270deg, #226cff 0%, #0688ff 100%);
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 36px;
       
        cursor: pointer;
      }

      ::-webkit-scrollbar {

   
         // width: 0 !important;
      }

      .div-rightbox{
        display: flex;
        flex-direction: column;
       
      }
      .div-logout {
        width: 200px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #E6EBF3;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, .5);
        line-height: 42px;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
      }

      .div-logout:hover{
        background: rgba(236, 245, 255, 1);
      }
</style>
