import axios from 'axios'
import loaclstore from 'store-gem.js'
import common from "common/js/common";
import Vue from 'vue';
let vm = new Vue()
// 是否正在刷新的标记
let isRefreshing = false
let localstore = new loaclstore()
//localstore.set("access_token", '960fc8a8-5097-4f87-a3f8-bfa0c448dd4d', 1000000);
//localstore.set("hdUserId",'1762',1000000);
let appVm = null
const sendThis = (_this) => {
  appVm = _this
}

export let requestFn = {
  sendThis, // 暴露函数
  // description: '我是一个工具类方法',
  // getData() {
  //     console.log(appVm) // 打印拿到的组件实例
  //     console.log(appVm.userProfile) // 打印组件中的data
  // },
  // callMethod() {
  //     appVm.clearForm() // 调用组件中的methods
  // } 
}
export function request (config) {
  var baseURL = '';
  if (process.env.VUE_APP_MODE === 'production') {
    baseURL = 'https://server.wiseai.chat/';
  } else if (process.env.VUE_APP_MODE === 'test') {
    baseURL = 'https://aitest.wiseai.chat/';
  } else {
    baseURL = 'https://server.wiseai.chat/';
  }
  // 创建一个axios实例
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 50000,
  })
  instance.interceptors.request.use(config => {

    //  config.headers.Authorization = "Bearer aba0202e-a509-46d6-a7f1-41ac75fcd1b6"; 


    console.log(localstore.get("access_token"));
    if (!common.isStringEmpty(localstore.get("access_token"))) {
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      // config.headers.Authorization = lstore.get("access_token");
      // 在params添加
      if (config.params) {
        if (config.params.access_token) {
          config.params.access_token = localstore.get("access_token")
        }
      } else {
        // console.log(config);
        // config.params = {
        //     access_token: localstore.get("access_token")
        // }
        config.url = common.replaceParamVal(config.url, 'access_token', localstore.get("access_token"))
      }
    }
    return config;
  }, err => {
    // console.log('网络错误')
    console.log(err, 'request');
    return Promise.reject(err);
  })

  instance.interceptors.response.use(
    response => {
      return response.data;
    }, error => {
      if (error.response && error.response.status == 401) {
        const config = error.response.config
        if (error.response.data.error_description == '验证码错误') {
          vm.$message.error('验证码错误')
          return
        } else {
          vm.$message.error('登录信息过期，请前去重新登录')
          return
        }
      } else {
        return Promise.reject(error);
      }

    }
  )
  return instance(config)
}
